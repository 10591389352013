<template>
  <div class="container">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { MENTOR_ROLE, PARTICIPANT_ROLE } from "@/constants";
import store from "@/store";
import { setUTMCookie } from "@/utils/cookies";
export default {
  name: "PageRegistration",
  async beforeRouteEnter(to, from, next) {
    const loginPage = "registration-login";
    await store.dispatch("getSeason").catch((error) => {
      console.log("error", error);
    });
    try {
      await store.dispatch("user/getMe");
    } catch (error) {
      console.log("error", error);
    }
    const user = store.state.user.user;
    const nextRoute = to.query.next;
    // Перенаправляем зарегистрированного участиника с экранов регистрации
    if (user?.role === PARTICIPANT_ROLE && !!user?.participant?.id) {
      // Если следующий роут это приглашение от наставника
      // и юзер уже зарегистрирован
      if (nextRoute && nextRoute.startsWith("/invite/u")) {
        next(nextRoute);
        return;
      }
      // Если это инвайт в профиль и юзер уже зареган
      if (to.query.profile_id) {
        next({
          name: "invite-profile",
          query: to.query,
        });
        return;
      }
      // Исключение - 3 шаг регистрации, где юзер выбирает профиль.
      // в этом месте юзер уже создан.
      if (to.name !== "registration-profile") {
        next(
          nextRoute
            ? nextRoute
            : {
                name: "user-index",
              }
        );
        return;
      }
    }

    // Если юзер уже зарегистрирован, и это ментор
    if (user && user.role === MENTOR_ROLE) {
      next(
        nextRoute
          ? nextRoute
          : {
              name: "mentor-index",
            }
      );
      return;
    }

    try {
      await store.dispatch("user/getTalentUser");
      next();
    } catch (error) {
      if (to.name === loginPage) {
        next();
        return;
      }

      next({
        name: loginPage,
        query: {
          next: to.fullPath,
        },
      });
    }
  },
  metaInfo() {
    return {
      title: "Регистрация на олимпиаду",
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
  },
  async created() {
    const { user } = this;
    setUTMCookie(this.$route.query);
    // Если еще не получали инфо о юзере, то запросим
    if (!user && !this.$store.state.user.guest) {
      try {
        await this.$store.dispatch("user/getMe");
      } catch (error) {
        console.log("error", error);
      }
    }
  },
};
</script>
